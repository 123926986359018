<template>
  <list v-if="entity" :entity="entity" :filters="filters">
    <template slot="list" slot-scope="data">
      <b-card>
        <b-table
          :fields="table.fields"
          :items="rows"
          :sort-by="data.sort.by"
          :sort-desc="data.sort.desc"
          @sort-changed="$emit('sortChange', $event)"
          :filter="filter"
          no-local-sorting
          responsive
        >
          <template slot="visible" slot-scope="data" v-if="!hasItems(data.item)">
            <c-switch
              variant="primary"
              type="default"
              size="xs"
              class="center-block align-middle mb-0"
              :checked="!!data.value"
              @change="changeVisible(data.item, $event)"
            ></c-switch>
          </template>

          <template slot="id" slot-scope="data" v-if="!hasItems(data.item)">
            <span :id="'id-row_' + data.index" :to="'/projects/' + data.item.id">{{ data.item.num }}</span>
            <popover :target="'id-row_' + data.index">
              <div><b>ERP:</b>&nbsp;{{ data.item.id || '-' }}</div>
              <div><b>Asana:</b>&nbsp;{{ (data.item.user && data.item.user.extra.asana_id) || '-' }}</div>
              <div><b>TD:</b>&nbsp;{{ (data.item.user && data.item.user.extra.td_id) || '-' }}</div>
              <div><b>amoCRM:</b>&nbsp;{{ (data.item.user && data.item.user.extra.amo_id) || '-' }}</div>
            </popover>
          </template>

          <template slot="name" slot-scope="data">
            <expander
              :level="data.item.level"
              :has-items="hasItems(data.item)"
              :expanded="data.item._expanded"
              @expand="expand(data.item)"
            >
              <template slot="text">
                <template v-if="!hasItems(data.item)">
                  <fa icon="pencil-alt" class="mr-10 edit-icon" size="sm" @click="showEmployeeModal(data.item)" />
                  <img v-if="data.item.user" class="user_pic" :src="data.item.user.photoLink" />
                  <b-link :to="'/employees/' + data.item.id">
                    {{
                      data.item.first_name + ' ' + data.item.patronymic + ' ' + data.item.surname || 'Без названия'
                    }} </b-link
                  >&nbsp;|&nbsp;&nbsp;
                  <span data-link class="_dollar" @click="showMotivationModal(data.item)">
                    <fa icon="dollar-sign" class="mr-1" size="sm"></fa>
                  </span>
                </template>
                <template v-else>
                  <div style="width: 300px; font-weight: bold" @click="expand(data.item)">
                    <fa
                      icon="pencil-alt"
                      class="mx-10 edit-icon"
                      size="sm"
                      @click.prevent="showDepartmentModal($event, data.item)"
                    />
                    {{ data.item.name }}
                  </div>
                </template>
              </template>
            </expander>
          </template>

          <template slot="department" slot-scope="data" v-if="data.item.department">
            {{ (data.item.department && data.item.department.name) || '-' }}
          </template>

          <template slot="position" slot-scope="data">
            {{ data.item.position || '' }}
          </template>

          <template slot="post" slot-scope="data">
            {{ data.item.post || '' }}
          </template>

          <template slot="login" slot-scope="data" v-if="data.item.user">
            {{ (data.item.user && data.item.user.username) || '' }}
          </template>
        </b-table>
        <edit-motivation
          :module="module"
          v-on:show="showMotivationForm"
          v-on:hide="hideMotivationForm"
        ></edit-motivation>
        <edit-employee
          :module="module"
          v-on:updated="onEmployeeUpdate"
          v-on:show="showEmployeeForm"
          v-on:hide="hideEmployeeForm"
        ></edit-employee>
        <edit-department
          :module="module"
          v-on:updated="onDepartmentUpdate"
          v-on:show="showDepartmentForm"
          v-on:hide="hideDepartmentForm"
        ></edit-department>
        <edit-settings :module="module"></edit-settings>
      </b-card>
    </template>
  </list>
</template>

<script>
  import entityMixins from '../../mixins/entity'
  import each from 'lodash/each'
  import cloneDeep from 'lodash/cloneDeep'
  import sortBy from 'lodash/sortBy'
  import stairsMixins from '../../mixins/stairs'
  import api from '../../assets/js/api'

  import Expander from '../../components/Expander'
  import Popover from '../../components/PopoverAlive'

  export default {
    name: 'EmployeesList',
    components: {
      Expander,
      Popover,
      list: () => import('../../components/List'),
      editEmployee: () => import('./EditEmployee'),
      editMotivation: () => import('./EditMotivation'),
      editSettings: () => import('./EditSettings'),
      editDepartment: () => import('./EditDepartment'),
      cSwitch: () => import('../../components/Switch'),
    },
    mixins: [entityMixins, stairsMixins],
    computed: {
      preparedItems() {
        return sortBy(this.items, 'name')
      },
    },
    data() {
      return {
        table: {
          fields: [
            {
              key: 'id',
              label: '#',
              class: '_id',
            },
            {
              key: 'visible',
              label: 'Актив.',
              class: 'text-center _switcher',
            },
            {
              key: 'name',
              label: 'ФИО',
              sortable: true,
            },
            {
              key: 'post',
              label: 'Должность',
              sortable: true,
            },
            {
              key: 'position',
              label: 'Роль',
              sortable: true,
            },
            {
              key: 'phone',
              label: 'Телефон',
            },
            {
              key: 'login',
              label: 'Email/Логин',
            },
          ],
        },
        modalItem: false,
        modalMotivationItem: false,
        filters: [
          {
            label: 'Быстрый поиск...',
            name: 'name',
          },
          {
            label: 'Показать неактивных',
            name: 'inactive',
            type: 'checkbox',
          },
        ],
      }
    },
    created() {
      let that = this

      this.$store.commit('currentView/setActions', [
        {
          label: '+ Добавить отдел',
          onClick() {
            that.showDepartmentModal()
          },
        },
        {
          label: '+ Добавить сотрудника',
          icon: 'user',
          onClick() {
            that.showEmployeeModal()
          },
        },
        {
          label: 'Настройка мотиваций',
          icon: 'dollar-sign',
          onClick() {
            that.showSettingsModal()
          },
        },
      ])
    },
    methods: {
      showEmployeeModal(item) {
        this.modalItem = item || { user: {} }

        this.$nextTick(() => {
          this.$root.$emit('bv::show::modal', 'edit-employee')
        })
      },
      showEmployeeForm(e) {
        e.loading = false
        e.form = cloneDeep(this.modalItem)

        delete e.form.parent
      },
      hideEmployeeForm(e) {
        e.form = false
      },
      onEmployeeUpdate(data) {
        let i = false

        each(this.items, cat => {
          each(cat.items, item => {
            if (item.id === data.result.id) {
              i = item
            }
          })
        })

        if (!data.isNew) {
          this.module.commit(`updateItem`, {
            merge: true,
            data: Object.assign({}, i, data.result),
            source: i,
          })
        } else {
          window.location.reload()
          // this.module.commit(`insertItems`, data.result)
        }
      },

      showDepartmentModal(e, item) {
        if (e) {
          e.stopPropagation()
          e.preventDefault()
        }

        this.modalItem = item || { name: '' }

        this.$nextTick(() => {
          this.$root.$emit('bv::show::modal', 'edit-department')
        })
      },
      showDepartmentForm(e) {
        e.loading = false
        e.form = cloneDeep(this.modalItem)

        delete e.form.items
        delete e.form.parent
      },
      hideDepartmentForm(e) {
        e.form = false
      },
      onDepartmentUpdate(data) {
        let i = false

        each(this.items, cat => {
          if (cat.id === data.result.id) {
            i = cat
          }
        })

        if (!data.isNew) {
          this.module.commit(`updateItem`, {
            merge: true,
            data: Object.assign({}, i, data.result),
            source: i,
          })

          this.dataPrepare(this, false)
        } else {
          window.location.reload()
          // this.module.commit(`insertItems`, data.result)
        }
      },

      showMotivationModal(item) {
        this.modalMotivationItem = item

        this.$nextTick(() => {
          this.$root.$emit('bv::show::modal', 'edit-motivation')
        })
      },
      showMotivationForm(e) {
        e.loading = true

        e.form = {
          user: cloneDeep(this.modalMotivationItem),
        }

        api.base
          .get({
            path: 'motivations/get-by-employee/' + this.modalMotivationItem.id,
          })
          .then(result => {
            e.motivation = result.data
            e.history = result.history
            e.checkMotivations()
            e.loading = false
          })
      },
      hideMotivationForm(e) {
        e.form = false
      },

      showSettingsModal() {
        this.$nextTick(() => {
          this.$root.$emit('bv::show::modal', 'edit-settings')
        })
      },

      resultHandler(result) {
        result.items.forEach(item => {
          item._expanded = true
        })

        this.dataPrepare(result, false, 'preparedItems')
      },
    },
  }
</script>

<style lang="scss" scoped>
  /deep/ {
    ._dollar {
      svg {
        margin: 5px 7px 0 0 !important;
      }
    }

    ._id {
      width: 40px !important;
      max-width: 40px !important;
      min-width: 40px !important;
      text-align: center;
    }

    ._switcher {
      width: 50px !important;
      max-width: 50px !important;
      min-width: 50px !important;
    }

    .expander[data-has-items='true'] {
      margin-left: -96px !important;
    }
    .expander[data-level='1'] {
      padding-left: 0 !important;
    }

    .user_pic {
      width: 17px;
      height: 17px;
      border-radius: 17px;
      margin: 0 10px 0 0;
    }

    th {
      &:first-child {
        width: 50px;
      }
      &:nth-child(2) {
        width: 200px;
      }
    }
  }
</style>
